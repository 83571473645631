import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../layouts/layout';

const HomeIndex = ({ data }) => {
  const image = getImage(data.strapiHomepage.hero.picture.localFile);

  return (
    <Layout seo={data.strapiHomepage?.seo}>
      <div className='flex mb-8'>
        {image && (
          <div className='relative w-16 h-16 mr-8'>
            <GatsbyImage
              image={image}
              alt='profile picture'
              className='w-full h-full border rounded-full'
            />
          </div>
        )}
        <div className='flex flex-col justify-center'>
          <span className='text-lg font-medium'>{data.strapiHomepage?.hero?.bio}</span>
        </div>
      </div>
    </Layout>
  );
};

export const HomeQuery = graphql`
  query FetchHomeData {
    strapiHomepage {
      hero {
        bio
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
          }
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;

export default HomeIndex;
